<template>
    <div class="outerBox">
		<div class="innerBox">
			<div class="keyLine">
				<key class="key" :keyName="['收起','']"></key>
				<key class="key" :keyName="['!','1']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['@','2']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['#','3']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['$','4']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['%','5']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['^','6']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['&','7']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['*','8']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['(','9']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="[')','0']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['-','-']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['+','=']" @mousedown="handleKeydown"></key>
				<key class="key backspace" :keyName="['backspace','']" @mousedown="handleBack"></key>
			</div>
			<div class="keyLine">
				<key class="key tabKey" :keyName="['Tab','']"></key>
				<key class="key" :keyName="['Q']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['W']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['E']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['R']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['T']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['Y']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['U']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['I']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['O']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['P']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['{','[']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['}',']']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['|','\\']" @mousedown="handleKeydown"></key>
			</div>
			<div class="keyLine">
				<key class="key caps" :keyName="['Caps','Lock']" @mousedown="handleCapslock" :active="capsLock"></key>
				<key class="key" :keyName="['A']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['S']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['D']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['F']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['G']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['H']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['J']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['K']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['L']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="[':',';']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="[doubleQuote,'\'']" @mousedown="handleKeydown"></key>
				<key class="key enterKey" :keyName="['Enter','']" @mousedown="handleEnter"></key>
			</div>
			<div class="keyLine">
				<key class="key shiftKey" :keyName="['↑ Shift','']" :active="shift" @mousedown="handleShift"></key>
				<key class="key" :keyName="['Z']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['X']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['C']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['V']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['B']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['N']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['M']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['<',',']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['>','.']" @mousedown="handleKeydown"></key>
				<key class="key" :keyName="['?','/']" @mousedown="handleKeydown"></key>
				<key class="key shiftKey" :keyName="['Shift','']" :active="shift" @mousedown="handleShift"></key>
			</div>
			<div class="keyLine">
				<key class="key" :keyName="['ctrl','']"></key>
				<key class="key" :keyName="['Fn','']"></key>
				<key class="key" :keyName="['alt','']"></key>
				<key class="key spaceKey" :keyName="['','']" @mousedown="handleSpace"></key>
				<key class="key" :keyName="['alt','']"></key>
				<key class="key" :keyName="['ctrl','']"></key>
				<key class="key" :keyName="['重输','']" @mousedown="handleRedo"></key>
			</div>
		</div>
	</div>
</template>

<style lang="less" scoped>
.outerBox {
	width: 48.8vw;
	height: 33.33vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	border-radius: 1.85vh;
	
	.innerBox {
		width: 46.36vw;
		height: 30vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.keyLine {
			height: 5.4vh;
			display: flex;
			justify-content: space-between;

			.key {
				height: 5.4vh;
				width: 3vw;

			}
			.backspace {
				width: 5vw;
			}
			.tabKey {
				width: 4.4vw;
			}
			.caps {
				width: 5vw;
			}
			.enterKey {
				width: 5vw
			}
			.shiftKey {
				width: 6.6vw
			}
			.spaceKey {
				width: 26vw
			}
		}
	}
}
</style>

<script>
const key = () => import('@/views/components/key.vue');

export default {
	data(){
		return {
			doubleQuote: '"',
			shift: false,
			capsLock: false
		}
	},
	components:{
		key
	},
	mounted(){
		return false;
	},
	methods:{
		handleClick(){
			this.$emit('click');
		},
		handleKeydown(n){
			let output = ''
			if((this.shift && !this.capsLock)||(!this.shift && this.capsLock)){
				output = n[0]
			}else{
				if(n.length == 1){
					let b = n[0].toLowerCase();
					n.push(b);
				}
				output = n[1];
			}
			this.$emit('onKeyboard',output);
		},
		handleCapslock(){
			this.capsLock = !this.capsLock;
		},
		handleShift(){
			this.shift = !this.shift;
		},
		handleEnter(){
			this.$emit('onKeyboard', 'enter');
		},
		handleSpace(){
			this.$emit('onKeyboard', 'space');
		},
		handleBack(){
			this.$emit('onKeyboard', 'back');
		},
		handleRedo(){
			this.$emit('onKeyboard', 'redo');
		}
	},
	updated(){
	}
}
</script>

